<template>
  <v-app>
    <v-card width="95%" class="mx-auto mt-5">
    <v-card-title>
      ログイン
    </v-card-title>
    <v-card-text>
      <v-form>
        <div class="signin">
          <v-text-field
            id="email"
            prepend-icon="mdi-account-circle"
            label="メールアドレス"
            v-model="name"
          />
          <v-text-field
            id="password"
            v-bind:type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            prepend-icon="mdi-lock"
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            v-model="password"
          />
          <v-alert
            v-model="alert"
            close-text="Close Alert"
            color="red accent-4"
            dark
            dismissible
            class="ma-5"
          >
        {{error_message}}
      </v-alert>
            <v-btn id="submit" class="info" @click="doLogin">ログイン</v-btn>
        </div>
      </v-form>

      <v-p v-if="id">新しいアカウントを作成しますか？
        <router-link  :to="`/signup/${id}`" id="singup">新規登録</router-link>
      </v-p>
    </v-card-text>
    </v-card>
  </v-app>
</template>

<script>

import Firebase from '../config/firebase';
export default {
  name: 'login',
  created: function() {
    Firebase.onAuth()
  },
  data(){
    return {
      showPassword: false,
      error_message: "",
      alert: false
    }
  },
  props: {
    clinic_id: String,
    to_url: String
  },
  computed: {
    id() {
        return this.$route.params.id
    },
  },
  methods: {
    // ログイン処理
    async doLogin() {
      //IDの入力フォームのバリデーション
      if (!this.name) {
        this.error_message = "ログインできません。メールアドレスを入力してください。"
        this.alert = true
        return
      }
      if(!this.password){
        this.error_message = "ログインできません。パスワードを入力してください。"
        this.alert = true
        return
      }

      let thenProm = await Firebase.login(this.name, this.password).then(result => {
        return result;
      })
      if (thenProm) {
        if (this.to_url !== undefined) {
          if (this.to_url == "patient_regster") {
            this.$router.push({ name: "patient_regster" , params: {id : this.id}})
          }else if (this.to_url ==  "prescription") {
            this.$router.push({ name: "prescription" , params: {id : this.id}})
          } else {
            this.$router.push({ name: "home", params: {id : this.id}})
          }
        } else {
            this.$router.push({ name: "home", params: {id : this.id}})
        }
      } else {
        this.error_message = "ログインできません。登録したメールアドレス、パスワードをご確認ください。"
        this.alert = true
      }


    }
  }
};
</script>
